<template>
  <div class="login">
    <div class="login-card">
      <div class="login-card-title">{{ $t("登录") }}</div>
      <el-form>
        <el-form-item>
          <el-input v-model="form.username" :placeholder="$t('输入手机号/邮箱')" clearable>
            <svgIcon icon="icon-user" widthName="20" heightName="20" slot="prefix" class="login-card-prefix-icon">
            </svgIcon>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input show-password v-model="form.password" :placeholder="$t('输入你的密码')" clearable>
            <svgIcon icon="icon-locked-new" widthName="20" heightName="20" slot="prefix" class="login-card-prefix-icon">
            </svgIcon>
          </el-input>
        </el-form-item>
        <el-form-item v-if="rule.code_check == 1 || rule.code_check == 2" class="ver-piccode-item">
          <el-input v-model="form.verificationCode" :placeholder="$t('输入图形验证码')" clearable>
            <span slot="prefix">
              <span class="iconfont icon-yanzhengma"></span>
            </span>
            <span slot="suffix" @click="getCaptcha">
              <el-image class="login-card-captchaImage" :src="captchaImage" fit="cover"></el-image>
            </span>
          </el-input>
        </el-form-item>
        <a href="javascript:;" @click="toLiveSuppert" class="login-card-link">{{ $t('客服') }}</a>
        <el-button class="button_fullwidth button_blue" :loading="LoginLoading" @click="onSubmit">{{ $t('登录')
          }}</el-button>
      </el-form>
    </div>

    <twoFa ref="twofa" @enter="twoFaEnter"></twoFa>

  </div>
</template>

<script>
import svgIcon from "@/components/svg.vue";
import getDevice from "@/common/getDevice";
import twoFa from "@/components/twoFa.vue";
import {
  login_api,
  captcha_api,
} from "@/api/index";
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
        verificationCode: "",
        google_code: ""
      },
      rule: this.$helper.get("rule"),
      login_code_key: "",
      LoginLoading: false
    }
  },
  components: { svgIcon, twoFa },
  methods: {
    // 获取登录、注册验证码
    async getCaptcha() {
      await captcha_api().then(res => {
        if (res && res.data.code == 1) {
          this.captchaImage = res.data.data.base64;
          this.login_code_key = res.data.data.key;
        }
      });
    },

    // 登录
    async onSubmit() {
      if (this.form.username == "") {
        this.errorTips("输入你的用户名");
        return;
      }
      if (this.form.password == "") {
        this.errorTips("输入你的密码");
        return;
      }
      if (this.code_check == 1 || this.code_check == 2) {
        if (this.form.verificationCode == "") {
          this.errorTips("输入图形验证码");
          return;
        }
      }
      this.LoginLoading = true;
      const data = {
        username: this.form.username,
        password: this.form.password,
        code_key: this.login_code_key,
        code_value: this.form.verificationCode,
        google_code: this.form.google_code,
        device_type: getDevice()
      }
      const res = await login_api(data)
      if (res && res.data.code == 1) {
        this.updateUserInfo();
        this.$helper.set("tokenInfo", res.data.data);
        this.$store.commit("$vuexSetUserTokenState", res.data.data.token);
        this.$store.commit("$vuexSetUserTokenKeyState", res.data.data.userTokenKey);
        this.$store.commit("$vuexSetLoginState", true);
        this.$message({
          customClass: "successMessage",
          showClose: false,
          message: this.$t("登录成功"),
          center: true,
          type: "success",
          duration: "1000"
        });
        this.$router.replace("/")
      } else if (res && res.data.code == 109) {
        this.$refs.twofa.show("login_check");
      } else if (res && res.data.code == 110) {
        this.$refs.twofa.show("login_check");
        this.errorTips(res.data.msg);
      } else {
        this.errorTips(res.data.msg, {
          showClose: false,
          duration: "2000",
          onClose: () => {
            this.getCaptcha();
          }
        });
      }
      this.LoginLoading = false;
    },

    twoFaEnter(code, type) {
      if (type === "login_check") {
        this.form.google_code = code;
        if (code) {
          this.onSubmit();
        }
        // this.$refs.twofa.close();
      }
    },
  },
  mounted() {
    if (this.rule.code_check == 1 || this.rule.code_check == 2) {
      this.getCaptcha()
    }
  }
}
</script>

<style scoped lang="less" src="@/assets/css/singleLogin.less"></style>